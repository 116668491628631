import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest, from, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {concatMap, delay, filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {SchoolUsersDbService} from '../services/school-users-db.service';
import {TenantService} from '../services/tenant.service';
import {login, setUserPermissions, userDetailsUpdated, userLoaded} from './user.actions';
import {PlatformActions, UserActions} from './action-types';
import {NewsletterService} from '../services/newsletter.service';
import {AppState} from './index';
import {isAdmin, isLoggedIn, selectTenantInfo, selectUser, selectUserPermissions} from './selectors';
import {LoadingService} from '../shared/services/loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {setAppSumoUpgradeOfferBanner, setSessionLanguage, translationsLoaded} from './platform.actions';
import {TrackingService} from '../services/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {SupportedLanguage} from '../models/supported-language.model';
import {environment} from '../../environments/environment';
import { Auth, authState, user } from '@angular/fire/auth';
import { idTokenResult } from '@angular/fire/auth-guard';
import {isLifetimeCustomer, showAppSumoMonthlyUpgradeOffer} from '../common/appsumo-utils';

declare const mixpanel: any;

@Injectable()
export class UserEffects {

  login$ = createEffect(() => authState(this.afAuth)
    .pipe(
      tap(user => console.log('AUTH login$ effect authState():', user)),
      filter(user => !!user),
      map(user => login({displayName: user.displayName, email: user.email, pictureUrl: user.photoURL, id: user.uid}))
    ));

  saveUserProfile$ = createEffect(() => combineLatest([ this.actions$.pipe(ofType(UserActions.login)), this.tenant.tenantId$])
    .pipe(
      concatMap(([login, tenantId]) => this.loading.showLoaderUntilCompleted(
        from(this.users.saveUserProfileIfNeeded(tenantId, login.id, login.email, login.pictureUrl, login.displayName))))
    ),
    {dispatch: false});

  saveLastSeenAt$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(UserActions.userLoaded),
        delay(5000),
        concatMap(action =>
          from(this.users.saveLastSeenAt(this.tenant.id, action.user.id)))
      ),
    {dispatch: false});

  loadUser$ = createEffect(() => combineLatest([authState(this.afAuth), this.tenant.tenantId$])
    .pipe(
      tap((data) => console.log('AUTH Loading user from database:', JSON.stringify(data))),
      filter(([user]) => !!user?.uid),
      concatMap(([user]) => this.loading.showLoaderUntilCompleted(this.users.findUserByUid(user.uid))),
      concatMap(user => {
        const actions: any[] = [userLoaded({user})];
        if (user.email?.endsWith('onlinecoursehost.com')) {
          // TODO delete this logic, if it's confirmed it's no longer needed actions.push(grantFounderPlan());
        }
        return actions;
      })
    ));

  identifyMixPanelUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.userLoaded),
    withLatestFrom(this.store.pipe(select(selectUserPermissions))),
    delay(5000), // wait for the mix panel script to be loaded
    tap(([action, permissions]) => {

      if (permissions?.isAdmin) {

        const user = action.user,
          email = user.email;

        if (!user) {
          console.log(`Could not report user to mixpanel, user is null`);
        }

        if (typeof mixpanel != 'undefined' && environment.production) {

          if (!user.mixPanelInitialized) {

            console.log(`Calling mixpanel.alias() and mixpanel.people.set()`);

            // link the anonymous profile to the new account
            mixpanel.alias(email);

            mixpanel.people.set({
              "Type": "Lead",
              "$name": user?.displayName ?? 'Unknown',
              "$email": email,
            });

            this.users.updateUser(user.id, {mixPanelInitialized: true}).subscribe();

          }

          console.log(`Calling mixpanel.identify() for user ${email}`);

          // Set this to a unique identifier for the user performing the event.
          mixpanel.identify(email);
        }
        else {
          console.log(`Mixpanel is not defined, could not report user ${email} to mixpanel`);
        }

      }
      else {
        console.log(`Not reporting user to mixpanel, user is not an admin.`);
      }

    })),
    {dispatch: false});

  userRoles$ = createEffect(() => idTokenResult(user(this.afAuth))
    .pipe(
      filter(result => !!result),
      map(result => {

        const allowedCourses:string[] = [];

        for (let allowedCourseId of Object.keys(result?.claims?.allowedCourses ?? {})) {
          allowedCourses.push(allowedCourseId);
        }

        return setUserPermissions({
        isAdmin: result.claims?.isAdmin as boolean ?? false,
        isCourseAdmin: result.claims?.isCourseAdmin as boolean ?? false,
        isPlatformAdmin: result.claims?.isPlatformAdmin as boolean ?? false,
        allowedCourses
      });
})
    ));

  addNewUserToNewsletter$ = createEffect(() => this.actions$.pipe(
      ofType(UserActions.userLoaded),
      filter(action => !action.user.addedToNewsletter),
      withLatestFrom(this.store.pipe(select(selectTenantInfo))),
      filter(([action, tenant]) => tenant.isNewsletterIntegrationActive),
      concatMap(([action]) => this.newsletter.addToNewsletter(action.user.email)),
      tap(() => {
        this.tracking.reportStudentLead();
        console.log('Reporting Lead to Facebook due to add to newsletter.');
      })
    ),
    {dispatch: false});

  reportStudentAccountCreationToFacebook$ = createEffect(() => this.actions$
      .pipe(
        ofType(UserActions.userLoaded),
        withLatestFrom(this.store.pipe(select(selectUserPermissions))),
        filter(([action, permissions]) => !action.user?.leadReportedToFacebook && !permissions.isAdmin),
        tap(() => {
          this.tracking.reportStudentLead();
          console.log('Reporting Lead to Facebook due to account creation');
        }),
        concatMap(([action, permissions]) => this.users.updateUser(action.user.id, {leadReportedToFacebook: true}))
      ),
    {dispatch: false});

  updateUserLanguage$ = createEffect(() => this.actions$
    .pipe(
      ofType(UserActions.updateUserLanguage),
      withLatestFrom(
        this.store.pipe(select(selectUser)),
        this.store.pipe(select(isLoggedIn)),
        ),
      concatMap( ([action, user, isLoggedIn]) => {

        // if the user is not logged in, switch languages
        if (!isLoggedIn) {
          return of(action);
        }

        return this.loading.showLoaderUntilCompleted(this.users.updateUser(user.id, {preferredLanguage: action.language}))
        .pipe(
          map(() => action)
        );

      }),
      tap((action) => {

        this.snackBar.open(`Language switched successfully.`, 'Close', {duration: 3000});

        this.translate.use(action.language);

      }),
      map(action => setSessionLanguage({language: action.language}))
    ));

  i18nIfNotLoggedIn$ = createEffect(() => this.actions$
    .pipe(
      ofType(PlatformActions.setTenantInfo),
      withLatestFrom(
        this.store.pipe(select(isLoggedIn)),
        this.store.pipe(select(selectTenantInfo))
      ),
      map(([action, isLoggedIn, tenant]) => {
        // if the user is not logged in, use the default site language, or English if no site language is available
        // otherwise if the user is logged in, wait for the user to be loaded (see setSessionLanguage$ effect below)
        if (!isLoggedIn && tenant?.languageConfig?.siteLanguage) {
          const language = tenant?.languageConfig?.siteLanguage ?? "en";
          console.log(`User is not logged in, using language ${language}.`);
          this.store.dispatch(setSessionLanguage({language}));
        }
        // default to English
        else {
          console.log(`User is not logged in, and no website language is defined. Defaulting to English.`);
          this.store.dispatch(setSessionLanguage({language: "en"}));
        }
      })
    ), {dispatch: false});

  setSessionLanguage$ = createEffect(() => this.actions$
    .pipe(
      ofType(UserActions.userLoaded),
      withLatestFrom(
        this.store.pipe(select(selectTenantInfo)),
        this.store.pipe(select(isAdmin)),
      ),
      map(([action, tenant, isAdmin]) => {

        const languageConfig = tenant?.languageConfig,
          preferredUserLanguage = action.user?.preferredLanguage,
          siteLanguage = tenant?.languageConfig?.siteLanguage;

        let sessionLanguage: SupportedLanguage = "en";

        if (preferredUserLanguage && (isAdmin || languageConfig?.allowStudentsToOverride)) {
          console.log(`Using the user preferred language ${preferredUserLanguage}`);
          sessionLanguage = preferredUserLanguage;
        }
        else if (siteLanguage) {
          console.log(`Using the default website language: ${siteLanguage}`);
          sessionLanguage = siteLanguage;
        }

        return sessionLanguage;

      }),
      map((language) => setSessionLanguage({language}))
    ));

  activateSessionLanguage$ = createEffect(() => this.actions$
    .pipe(
      ofType(PlatformActions?.setSessionLanguage),
      tap(action => {
        if (action.language) {
          this.translate.use(action.language)
            .subscribe(translations => {
              this.store.dispatch(translationsLoaded({translations}));
            });
        }
      })
    )
  , {dispatch: false});

  newsletterStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.updateNewsletterStatus),
    withLatestFrom(this.store.pipe(select(selectUser))),
    concatMap(([action, user]) => this.loading.showLoaderUntilCompleted(this.users.updateNewsletterStatus(user.id, action.unsubscribeFromNewsletter)))
  ), {dispatch: false});

  // show or hide the appsumo upgrade offer banner
  setAppSumoUpgradeOffer$ = createEffect(() => this.actions$.pipe(
    // wait for the user profile to be loaded from the database
    ofType(UserActions.userLoaded),
    withLatestFrom(
        this.store.pipe(select(selectTenantInfo)),
        this.store.pipe(select(selectUserPermissions)),
    ),
    filter(([action, tenant, permissions]) => !!tenant && !!permissions && !!action?.user),
    map(([action, tenant, permissions]) => setAppSumoUpgradeOfferBanner({
      showAppSumoUpgradeBanner:showAppSumoMonthlyUpgradeOffer(tenant, permissions)
    }))
  ));

  constructor(private actions$: Actions,
              private afAuth: Auth,
              private tenant: TenantService,
              private users: SchoolUsersDbService,
              private loading: LoadingService,
              private newsletter: NewsletterService,
              private store: Store<AppState>,
              private tracking: TrackingService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {

  }

}
